import React from 'react'
import Slider from 'react-slick';
import ava01 from '../../../assets/images/ava-1.jpg';
import ava02 from '../../../assets/images/ava-2.jpg';
import ava03 from '../../../assets/images/ava-3.jpg';
import './Testimonial.css';

const TestimonialSlide = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite:true,
        speed:1000,
        autoplaySpeed:3000,
        swipeToSlide:true,
        slidesToShow:1,
        slidesToScroll:1,
    }
  return (
    <Slider {...settings}>
        <div>
            <p className="review_test">
               " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolore corrupti 
                eaque eveniet omnis perspiciatis amet animi totam modi ullam?"
            </p>
            <div className='slider_content d-flex align-items-center gap-3 '>
                <img src={ava01} alt="avatar" className='pic-test' />
                <h6>Max Path</h6>
            </div>
        </div>
        <div>
            <p className="review_test">
               " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolore corrupti 
                eaque eveniet omnis perspiciatis amet animi totam modi ullam?"
            </p>
            <div className=' slider_content d-flex align-items-center gap-3 '>
                <img src={ava02} alt="avatar" className=' pic-test' />
                <h6>Pauel Pin</h6>
            </div>
        </div>
        <div>
            <p className="review_test">
               " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolore corrupti 
                eaque eveniet omnis perspiciatis amet animi totam modi ullam?"
            </p>
            <div className=' slider_content d-flex align-items-center gap-3 '>
                <img src={ava03} alt="avatar" className='pic-test' />
                <h6>Daniel Park</h6>
            </div>
        </div>
        <div>
            <p className="review_test">
               " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolore corrupti 
                eaque eveniet omnis perspiciatis amet animi totam modi ullam?"
            </p>
            <div className=' slider_content d-flex align-items-center gap-3 '>
                <img src={ava03} alt="avatar" className='pic-test' />
                <h6>Daniel Park</h6>
            </div>
        </div>
        <div>
            <p className="review_test">
               " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolore corrupti 
                eaque eveniet omnis perspiciatis amet animi totam modi ullam?"
            </p>
            <div className=' slider_content d-flex align-items-center gap-3 '>
                <img src={ava03} alt="avatar" className='pic-test' />
                <h6>Daniel Park</h6>
            </div>
        </div>
    </Slider>
  )
}

export default TestimonialSlide