import React, {useEffect,useState} from 'react'
import Helmet from '../components/Helmet/Helmet'
import {Container,Row,Col,ListGroup,ListGroupItem} from 'reactstrap';
import Hero from '../assets/images/37909.jpg';
import '../style/hero-sec.css';
import {Link} from 'react-router-dom';
import Category from '../components/UI/category/Category';
import '../style/home.css';
import featureImg01 from '../assets/images/service-01.png';
import featureImg02 from '../assets/images/service-02.png';
import featureImg03 from '../assets/images/service-03.png';
import products from '../assets/data/products';
import foodCategoryImg01 from '../assets/images/hamburger.png';
import foodCategoryImg02 from '../assets/images/pizza.png';
import foodCategoryImg03 from '../assets/images/bread.png';
import ProductCard from '../components/UI/product-card/ProductCard';
import Loc from '../assets/images/locc.jpg';
import network from '../assets/images/testi.png';
import TestimonialSlide from '../components/UI/testimonial/TestimonialSlide';
const featureData = [
  {
    title:'Quick Delivery',
    imgUrl:featureImg01,
    desc: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id, excepturi.'
  },
  {
    title:'Super Dine In',
    imgUrl:featureImg02,
    desc: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id, excepturi.'

  },
  {
    title:'Easy Pick Up',
    imgUrl:featureImg03,
    desc: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id, excepturi.'

  },

]
const Home = () => {
  const [category, setCategory] = useState('ALL');
  const [allProducts, setAllProducts] = useState(products);
  const [hotPizza, setHotPizza] = useState([])

  useEffect(()=>{
    const filteredPizza = products.filter(item=> item.category === 'Pizza')
    const slicePizza = filteredPizza.slice(0,4)
    setHotPizza(slicePizza);

  },[])

  useEffect(() =>{
    if(category === 'ALL'){
      setAllProducts(products)
    }
    if(category === 'BURGER'){
      const filteredProducts = products.filter(item => item.category === 'Burger')
       setAllProducts(filteredProducts)
    }
    if(category === 'PIZZA'){
      const filteredProducts = products.filter(item => item.category === 'Pizza')
       setAllProducts(filteredProducts)
    }
    if(category === 'BREAD'){
      const filteredProducts = products.filter(item => item.category === 'Bread')
       setAllProducts(filteredProducts)
    }
  },[category])
  return (
    <Helmet title='Home' >
      <section className="pt-5">
        <Container>
            <Row>
               <Col lg='6' md='6'>
                  <div className="hero_content">
                    <h6 className='mb-3'>Make your order...</h6>
                    <h1 className='mb-4 hero_title'><span>HUNGRY ? </span> just wait <br/> food at<span> your door !</span></h1>
                    <p>Lorem ipsum dolor sit amet,
                       consectetur adipisicing elit. Dolor minus quo suscipit ratione vel fuga.</p>
                    <div className="hero_btn d-flex align-items-center gap-5 mt-4">
                      <button className='order_btn d-flex align-items-center justify-content-between'>Order now <i class="ri-arrow-right-s-line"></i></button>
                      <button className="all_foods-btn"><Link to="/foods">See All Foods</Link></button>
                    </div>
                    <div className="hero_service d-flex align-items-center gap-5 mt-5">
                      <p className='d-flex align-items-center gap-2'><span class="shipping_icon"><i class="ri-car-line"></i></span>{" "}No Shipping Charge</p>
                      <p className='d-flex align-items-center gap-2'><span class="check_icon"><i class="ri-shield-check-line"></i></span>{" "}100% secure checkout</p>

                    </div>
                  </div>
               </Col> 
                <Col lg='6' md='6'>
                  <div className="hero_image">
                      <img src={Hero} alt="hero" className='w-100' />
                  </div>
                  
                  
                </Col>
            </Row>
        </Container>
      </section>
      <section className='pt-0'>
        <Category/>
      </section>
      <section>
        <Container>
           <Row>
             <Col lg='12' className='text-center'>
               <h5 className='feature_subtitle mb-4'>What we serve</h5>
               <h2 className='feature_title'>Just sit at Home</h2>
               <h2 className='feature_title'>We will <span>take care</span></h2>
               <p className='mb-1 mt-4 feature_text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, amet.</p>
               <p className='feature_text'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur, distinctio.{" "}</p>
             </Col>
              {
                featureData.map((item, index)=> {
                  return(
                    <Col lg='4' md='6' sm='6' key={index} className="mt-5">
                     <div className="feature_item text-center px-5 py-3">
                       <img src={item.imgUrl} alt="img" className='w-25 mb-3' />
                       <h5 className='fw-bold mb-3'>{item.title}</h5>
                       <p>{item.desc}</p>
                     </div>
                    </Col>
                  )
                })
              }
             
             
           </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg='12' className='text-center'>
              <h2>Popular Foods</h2>
            </Col>
            <Col lg='12' >
              <div className="food_category d-flex align-items-center justify-content-center gap-4">
                 <button onClick={()=> setCategory('ALL')} className={`all_btn ${category === 'ALL'? 'foodBtnActive': '' }`}>All</button>
                 <button onClick={()=> setCategory('BURGER')} className={`d-flex align-items-center gap-2 ${category === 'BURGER'? 'foodBtnActive': '' }`}><img src={foodCategoryImg01} alt="burger" />Burger</button>
                 <button onClick={()=> setCategory('PIZZA')} className={`d-flex align-items-center gap-2 ${category === 'PIZZA'? 'foodBtnActive': '' }`}><img src={foodCategoryImg02} alt="pizza" />Pizza</button>
                 <button onClick={()=> setCategory('BREAD')} className={`d-flex align-items-center gap-2 ${category === 'BREAD'? 'foodBtnActive': '' }`}><img src={foodCategoryImg03} alt="bread" />Bread</button>

              </div>
            </Col>
            {
              allProducts.map(item=>{
                return(
                  <Col lg='3' md='4' sm="6" xs="6" key={item.id} className="mt-5">
                  <ProductCard item={item}/>
                </Col>
                )
              })
            }
          
            
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg='6' md='6' className='choose_sec'>
              <img src={Loc} alt="location" className='w-100' />
            </Col>
            <Col lg='6' md='6'>
              <div className="tasty_treat">
                <h2 className="tasty_title mb-4">Why Us ?</h2>
                <p className='tasty_desc'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                  Animi dicta, autem dolorem corporis itaque a.
                  Lorem ipsum dolor sit amet consectetur.</p>
                  <ListGroup className='mt-4'>
                    <ListGroupItem className='border-0 ps-0'>
                      <p className='choose_title d-flex align-items-center gap-2'> <i class="ri-checkbox-circle-line"></i> Fresh and Tasty{" "}</p>
                      <p className='choose_desc'> Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                     
                    </ListGroupItem>
                    <ListGroupItem className='border-0 ps-0'>
                      <p className=' choose_title d-flex align-items-center gap-2'> <i class="ri-checkbox-circle-line"></i> Quality Support{" "}</p>
                     
                      <p className='choose_desc'> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </ListGroupItem>
                    <ListGroupItem className='border-0 ps-0'>
                      <p className='choose_title d-flex align-items-center gap-2'> <i class="ri-checkbox-circle-line"></i> Fast Delivery{" "}</p>
                      <p className='choose_desc'> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                     
                    </ListGroupItem>
                  </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='pt-0'>
        <Container>
          <Row>
            <Col lg='12' className='text-center mb-5'>
              <h2>Hot Pizza</h2>
            </Col>
            {
              hotPizza.map(item=> (
                <Col lg='3' md='4' sm='6' key={item.id} className='mt-4'>
                  <ProductCard item={item}/>
                  
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>
      <section>
         <Container>
           <Row>
             
             <Col lg='6' md='6'>
              <div className='testimonial '>
              <h5 className='testimonial_title mb-3'>Testimonials</h5>
              <h2 className='testimonial_sec'>What our <span> customers </span>thinking ?!</h2>
              <p className='testimonial_desc'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque vitae ad doloribus
                </p>
              
               <TestimonialSlide/>
                
              </div>
             </Col>
             <Col lg='6' md='6' className='d-flex align-items-center justify-content-center mt-3'>
               <img src={network} alt="networks" className='w-100' />
             </Col>
           </Row>
         </Container>
      </section>
    </Helmet>
  )
}

export default Home