import React,{useRef,useEffect} from 'react'
import { Container } from 'reactstrap'
import './header.css';
import { NavLink, Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { cartUiActions } from '../../store/shopping-cart/cartUiSlice';
const NavBar =[
  {
    display:"Home",
    path:"/home"
  },
  {
    display:'Foods',
    path:'/foods'
  },
  {
    display:'Cart',
    path:'/cart'
  },
  {
    display:'Contact',
    path:'/contact'
  },
];
const Header = () => {
  const menuRef = useRef(null);
  const headerRef = useRef(null);
  const totalQuantity = useSelector(state => state.cart.totalQuantity);
  const dispatch = useDispatch();
  const toggleMenu = ()=>menuRef.current.classList.toggle('show_menu');

  const toggleCart = ()=>{
    dispatch(cartUiActions.toggle())
  }

  useEffect(()=>{
    window.addEventListener('scroll',()=>{
      if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
        headerRef.current.classList.add('header_shrink')
      }
      else{
        headerRef.current.classList.remove('header_shrink')
      }
    })
    return ()=> window.removeEventListener('scroll',null)
  },[])
  return (
    <div>
      <header className="header" ref={headerRef}>
          <Container>
             <div className="nav_wrapper d-flex align-items-center
             justify-content-between">
              <div className="logo">
                 <p className='logo_text'><span>F</span>ast<span>F</span>ood</p>
                
              </div>
                {/* menu */}
                <div className="navigation" ref={menuRef}  onClick={toggleMenu}>
                  <div className="menu d-flex align-items-center gap-5">
                    {
                      NavBar.map((item, index)=>{
                        return(
                          <NavLink
                          
                           to={item.path} key={index}
                          className={navClass => navClass.isActive ? 'active_menu':''}>
                          {item.display}
                        </NavLink>
                        )
                      })
                    }
                  </div>
                </div>
                {/* --------- nav right ---------- */}
                <div className="nav_right d-flex align-items-center gap-5 ">
                  <span className="cart_icon" onClick={toggleCart}>
                    <i class="ri-shopping-basket-line"></i>
                    <span className='cart_badge'>{totalQuantity}</span>
                  </span>
                  <span className="user">
                    <Link to='/login'>
                      <i class="ri-user-line"></i>
                    </Link>
                  </span>
                  <span className="mobile_menu" onClick={toggleMenu}>
                    <i class="ri-menu-line"></i>
                  </span>
                </div>
             </div>
          </Container>
      </header>
    </div>
  )
}

export default Header