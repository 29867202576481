import React from 'react'
import {Container, Row, Col,ListGroup,ListGroupItem} from 'reactstrap';
import './footer.css';
import {Link} from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
         <Row>
           <Col lg='3' md='4' sm='6'>
             <div className="footer_logo text-start">
                {/* <img src={logo} alt="logo" /> */}
                <p className='logo_text'><span>F</span>ast<span>F</span>ood</p>

                
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                  </p>
              </div>
           </Col>
           <Col lg='3' md='4' sm='6'>
             <h5 className="footer_title"> Delivery Time </h5>
             <ListGroup className="delivery_time-list">
               <ListGroupItem className=' delivery_time-item border-0 ps-0'>
                 <span> Sunday - Thursday </span>
                 <p>10:00am - 11:00pm</p>
               </ListGroupItem>
               <ListGroupItem className=' delivery_time-item border-0 ps-0'>
                 <span> Friday - Saturday </span>
                 <p>Off day</p>
               </ListGroupItem>
             </ListGroup>
             
           </Col>
           <Col lg='3' md='4' sm='6'>
           <h5 className="footer_title"> Contact </h5>
            <ListGroup className="delivery_time-list">
            <ListGroupItem className=' delivery_time-item border-0 ps-0'>
                 <p>Location : Uellendahler Straße, Wuppertal </p>
                 
               </ListGroupItem>
               <ListGroupItem className=' delivery_time-item border-0 ps-0'>
                 <span> Phone : + 49 (0) 1631859993 </span>
                 
               </ListGroupItem>
               <ListGroupItem className=' delivery_time-item border-0 ps-0'>
                 <span>Email : hadiszare@gmail.com </span>
                 
               </ListGroupItem>
             </ListGroup>
           </Col>
           <Col lg='3' md='4' sm='6'>
           <h5 className="footer_title"> Newsletter </h5>
           <p>Subscribe our newsletter</p>
           <div className="newsletter">
             <input type="email" placeholder='Enter your Email' name="" id="" />
             <span><i class="ri-send-plane-line"></i></span>
           </div>
           </Col>
         </Row>
         <Row className='mt-5'>
           <Col lg='6' md='6'>
             <p className='copyright'>Copyright -2022, website Developer</p>
           </Col>
           <Col lg='6' md='6'>
             <div className="social_links d-flex align-items-center gap-4 justify-content-end">
               <p className='m-0'>Follow : </p>
               <span><Link to='#myfacebook'><i class="ri-facebook-line"></i></Link></span>
               <span><Link to='#mygithub'><i class="ri-github-line"></i></Link></span>
               <span><Link to='#myyoutube'><i class="ri-youtube-line"></i></Link></span>
               <span><Link to='#mylinkedin'><i class="ri-linkedin-line"></i></Link></span>



             </div>
           </Col>
         </Row>
      </Container>
    </footer>
  )
}

export default Footer