import React from 'react'
import Helmet from "../components/Helmet/Helmet";
import CommenSection from '../components/UI/commen-section/CommenSection';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import '../style/contact.css';

const Contact = () => {
  return (
    <Helmet title='Contact'>
      <CommenSection title='Contact'/>
       <section>
         <Container>
           <Row>
           <Col lg='12'>
             <div className="social_link d-flex align-items-center gap-5 justify-content-center mt-4 mb-4">
              
               <span><Link to='#myfacebook'><i class="ri-facebook-line"></i></Link></span>
               <span><Link to='#mygithub'><i class="ri-github-line"></i></Link></span>
               <span><Link to='#myyoutube'><i class="ri-youtube-line"></i></Link></span>
               <span><Link to='#mylinkedin'><i class="ri-linkedin-line"></i></Link></span>
             </div>
           </Col>
           </Row>
         </Container>
       </section>
    </Helmet>
  )
}

export default Contact