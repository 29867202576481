import React from 'react';
import {Container,Row, Col} from 'reactstrap';
import categoryImg01 from '../../../assets/images/category-01.png';
import categoryImg02 from '../../../assets/images/category-02.png';
import categoryImg03 from '../../../assets/images/category-03.png';
import categoryImg04 from '../../../assets/images/category-04.png';
import './category.css';
const categoryData = [
    {
        dispaly: 'Fastfood',
        imgUrl: categoryImg01
    },
    {
        dispaly: 'Pizza',
        imgUrl: categoryImg02
    },
    {
        dispaly: 'Asian Food',
        imgUrl: categoryImg03
    },
    {
        dispaly: 'Row Meal',
        imgUrl: categoryImg04
    },


]
const Category = () => {
  return (
   <Container>
       <Row>
        {
            categoryData.map((item,index)=>{
                return(
                    <Col lg="3" md="4" sm="6" xs="6" className="mb-3">
                       <div className="category_item d-flex align-items-center gap-3">
                        <div className="category_img">
                            <img src={item.imgUrl} alt="img" />
                        </div>
                         <h6>{item.dispaly}</h6> 
                        </div> 
                    </Col>
                )
            })
        }
         
        </Row> 
   </Container>
  )
}

export default Category