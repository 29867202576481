import React,{useState,useEffect} from 'react'
import products from '../assets/data/products';
import { useParams } from 'react-router-dom'; 
import Helmet from '../components/Helmet/Helmet';
import {Container,Row,Col} from 'reactstrap';
import CommenSection from '../components/UI/commen-section/CommenSection';
import ProductCard from '../components/UI/product-card/ProductCard'
import '../style/product-detail.css';
import { useDispatch } from 'react-redux';
import {cartActions} from '../store/shopping-cart/cartSlice'

const FoodDetails = () => {

  const [tab,setTab] = useState('desc');
  const [enteredName, setEnteredName] = useState('');
  const [enteredEmail, setEnteredEmail] = useState('');
  const [reviewMsg, setReviewMsg] = useState('');
  const {id} = useParams();
  const dispatch = useDispatch();
  const product = products.find(product=>product.id === id)
  const [previewImg, setPreviewImg] = useState(product.image01);
  const {title,price,category,desc,image01} = product;
  const relatedProduct = products.filter(item => category === item.category)

  const addItem = ()=>{
    dispatch(cartActions.addItem({
      id,
      title,
      price,
      image01
    }))
  };
  const submitHandler = e =>{
    e.preventDefault();
    console.log(enteredName,enteredEmail,reviewMsg);
  }
  useEffect(()=>{
    setPreviewImg(product.image01)
  },[product])
  // go to the top after you choose another one
 useEffect(()=>{
  window.scrollTo(0,0)
 },[product])
  return (
    <Helmet title='Product-Details'>
       <CommenSection title={title}/>
        <section>
          <Container>
            <Row>
              <Col lg='2' md='2'>
                <div className="product_images">
                  <div className="img_item mb-5" onClick={()=>setPreviewImg(product.image01)}>
                    <img src={product.image01} alt="" className='w-50' />
                  </div>
                  <div className="img_item mb-5" onClick={()=>setPreviewImg(product.image02)}>
                    <img src={product.image02} alt="" className='w-50' />
                  </div>
                  <div className="img_item" onClick={()=>setPreviewImg(product.image03)}>
                    <img src={product.image03} alt="" className='w-50' />
                  </div>

                </div>
              </Col>
              <Col lg='4' md='4'>
                <div className="product_mail-img">
                  <img src={previewImg} alt="" className='w-100' />
                </div>
              </Col>
              <Col lg='6' md='6'>
                <div className="single_product-content">
                  <h2 className='product_title mb-3'>{title}</h2>
                  <p className='product_price'>{" "} Price : <span>
                   ${price}</span></p>
                  <p className='category mb-5'>Category : <span>{category}</span></p>
                  <button onClick={addItem} className='addToCart_btn'>Add To Cart</button>
                </div>
              </Col>
              <Col lg='12'>
                <div className="tabs d-flex align-items-center gap-3 py-2">
                 <h6 className={`${tab === 'desc'? 'tab_active':''}`} onClick={()=>setTab('desc')}>Description</h6>
                 <h6 className={`${tab === 'rev'? 'tab_active':''}`} onClick={()=>setTab('rev')}>Review</h6>
                </div>
                { ///tab code
                  tab === 'desc'? 
                   <div className="tab_content">
                     <p>{desc}</p>
                   </div> :  
                <div className="tab_form mb-3">
                  <div className="review pt-5">
                    <p className="user_name mb-0">
                        Hadis Zare
                    </p>
                    <p className="user_email">hadiszare@gmail.com</p>
                    <p className='feedback_text'>
                    great product
                    </p>
                  </div>
                  <div className="review">
                    <p className="user_name mb-0">
                        Hadis Zare
                    </p>
                    <p className="user_email">hadiszare@gmail.com</p>
                    <p className='feedback_text'>
                    great product
                    </p>
                  </div>
                  <form className='form' onSubmit={submitHandler}>
                    <div className='form_group'>
                      <input type="text" placeholder='Enter your name'
                      onChange ={e=>setEnteredName(e.target.value)} required/>
                    </div>
                    <div className='form_group'>
                      <input type="text" placeholder='Enter your Email'
                      onChange ={e=>setEnteredEmail(e.target.value)} required/>
                    </div>
                    <div className='form_group'>
                      <textarea type="text" rows={5} placeholder='Enter your message'
                       onChange ={e=>setReviewMsg(e.target.value)} required/>
                    </div>
                    <button type='submit' className='addToCart_btn'>Submit</button>
                  </form>
                </div>
                }
               
               
              </Col>
              <Col lg='12' className='mb-5 mt-4'>
                <h4 className='related_Product-title'>You might also like : </h4>


              </Col>
              {
                relatedProduct.map((item)=>{
                  return(
                    <Col lg='3' md='4' sm='6' xs='6' className='mb-4' key={item.id}> 
                     <ProductCard item={item}/>
                    </Col>
                  )
                })
              }
            </Row>
          </Container>
        </section>
    </Helmet>
  
  )
}

export default FoodDetails